import { Options } from '@frontend/fetch';
import { SchedulerServiceV3 } from '@frontend/schema';
import {
  AppointmentStatusWriteBackApiType,
  AppointmentWriteBackApiType,
  GetFeatureFlagStatusApiType,
  ListCalendarAvailabilitiesApiType,
  ListSourceTenantsApiType,
  PersonWriteBackApiType,
} from './types';

export const getListCalendarAvailabilities = async (
  req: ListCalendarAvailabilitiesApiType['input'],
  options?: Options
): Promise<ListCalendarAvailabilitiesApiType['output']> => SchedulerServiceV3.ListCalendarAvailabilities(req, options);

export const appointmentWriteback = async (
  req: AppointmentWriteBackApiType['input']
): Promise<AppointmentWriteBackApiType['output']> => SchedulerServiceV3.AppointmentWriteBack(req);

export const appointmentStatusWriteback = async (
  req: AppointmentStatusWriteBackApiType['input']
): Promise<AppointmentStatusWriteBackApiType['output']> => SchedulerServiceV3.AppointmentStatusWriteBack(req);

export const personWriteback = async (
  req: PersonWriteBackApiType['input']
): Promise<PersonWriteBackApiType['output']> => SchedulerServiceV3.PersonWriteBack(req);

export const listSourceTenants = async (
  req: ListSourceTenantsApiType['input']
): Promise<ListSourceTenantsApiType['output']> => SchedulerServiceV3.ListSourceTenants(req);

export const getFeatureFlagStatus = (req: GetFeatureFlagStatusApiType['input'], options?: Options) => {
  return SchedulerServiceV3.FeatureFlagStatus(req, options);
};
