import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';

export const tableStyles = css`
  height: 100%;
  padding-top: ${theme.spacing(1)};

  > [class$='TableToolbar']:first-of-type {
    padding: 0;
  }
`;

export const drillDownTableSize = css`
  max-height: 620px;
`;

export const cardStyle = css`
  border-radius: ${theme.borderRadius.medium};
  border: 1px solid ${theme.colors.neutral20};
  overflow: hidden;
  padding: ${theme.spacing(3)};
  position: relative;
`;

export const pageStyles = {
  tabPanel: css`
    flex: 1;
    overflow: auto;
  `,

  panelsWrapper: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    position: relative;
  `,

  customTabSelect: css`
    button[aria-selected='true'] {
      color: ${theme.colors.text.interactive};
      font-weight: ${theme.font.weight.semibold};
    }
  `,

  numberSearch: (isMobile?: boolean) => css`
    flex: ${isMobile ? 'none' : 1};
    margin-bottom: ${isMobile ? theme.spacing(1) : 0};
    width: ${isMobile ? '100%' : 'auto'};
  `,

  tableFullHeight: css`
    height: auto;

    > div:first-of-type {
      display: flex;
      flex-direction: column;
    }
  `,
};

export const filtersStyles = {
  mainWrapper: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(3)};
    position: relative;
  `,

  clearAll: css`
    color: ${theme.colors.text.interactive};
    font-weight: ${theme.font.weight.bold};
  `,

  filteredBadge: css`
    position: relative;

    &::after {
      background-color: ${theme.colors.text.interactive};
      border-radius: ${theme.borderRadius.full};
      content: '';
      height: ${theme.spacing(1.25)};
      left: ${theme.spacing(3)};
      position: absolute;
      top: ${theme.spacing(1)};
      width: ${theme.spacing(1.25)};
    }
  `,

  traySection: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  `,
};

export const stickyTabsStyles = css`
  background-color: ${theme.colors.white};
  isolation: isolate;
  padding-bottom: ${theme.spacing(2)};
  position: sticky;
  top: 0;
  z-index: 4;

  &:before {
    background-color: ${theme.colors.white};
    content: '';
    display: block;
    height: 100%;
    left: ${theme.spacing(-3)};
    position: absolute;
    top: 0;
    width: calc(100% + ${theme.spacing(6)});
    z-index: -1;

    @media screen and (min-width: ${breakpoints.medium.min}px) {
      left: ${theme.spacing(-4)};
      width: calc(100% + ${theme.spacing(8)});
    }
  }

  &.sticky-shadow {
    &:before {
      box-shadow: ${theme.shadows.light};
    }
  }
`;

export const callIntelStyles = {
  multiComparison: css`
    flex: 1;
    display: flex;
    justify-content: flex-start;

    @media screen and (min-width: 730px) {
      justify-content: flex-end;
    }

    > button {
      width: auto;

      .icon {
        display: inline-block;
        margin-right: ${theme.spacing(1.75)};
        width: ${theme.spacing(3)};
      }
    }
  `,
};

export const hr = css`
  border: none;
  border-top: 1px solid ${theme.colors.neutral20};
`;
