import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const dashboardChartStyle = css`
  border-radius: ${theme.borderRadius.medium};
  border: 1px solid ${theme.colors.neutral20};

  .chart-content {
    border: none;
    justify-content: space-between;
  }
`;
