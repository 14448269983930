import { useAppScopeStore } from '@frontend/scope';

export const useIsMulti = () => {
  const { accessibleLocationData, scopeHierarchy, selectedLocationIds } = useAppScopeStore();

  // As per the backend compatibility, for org id, return:
  // 1. Location id when single location is selected
  // 2. Group id when multi-location struture is selected and group id for all the locations is same
  // 3. Org id when multi-location struture is selected and group id for all the locations is different

  // We cannot depend on the value received using isSingleTypeScope from the store
  // because it return true when only one location from a multi struture is selected and does not fullfill our need
  const scoppedLocationIds = Object.values(
    scopeHierarchy?.[accessibleLocationData?.[selectedLocationIds[0]]?.orgId] ?? {}
  ).flat();
  const isMulti = scoppedLocationIds.length > 1;
  let orgId = isMulti ? accessibleLocationData?.[selectedLocationIds[0]]?.groupId : selectedLocationIds[0];

  if (isMulti) {
    const groupIds = selectedLocationIds.map((id) => accessibleLocationData[id]?.groupId);
    const allSameGroupId = groupIds.every((groupId) => groupId === groupIds[0]);
    orgId = allSameGroupId ? groupIds[0] : accessibleLocationData?.[selectedLocationIds[0]]?.orgId;
  }

  return {
    isMulti,
    orgId,
  };
};
