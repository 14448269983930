import { UseQueryOptions } from 'react-query';
import { Options } from '@frontend/fetch';
import { useMutation, useQuery } from '@frontend/react-query-helpers';
import {
  getFeatureFlagStatus,
  getListCalendarAvailabilities,
  appointmentWriteback,
  appointmentStatusWriteback,
  listSourceTenants,
  personWriteback,
} from './api';
import { scheduleBookingSiteQueryKeys } from './query-keys';
import {
  GetFeatureFlagStatusApiType,
  ListCalendarAvailabilitiesApiType,
  PersonWriteBackApiType,
  AppointmentStatusWriteBackApiType,
  AppointmentWriteBackApiType,
} from './types';

const CACHE_AND_STALE_TIME_FOR_APPOINTMENT_OPENINGS = 1000 * 60 * 3; // 3 minutes

export const useAppointmentWriteback = () => {
  return useMutation({
    mutationFn: (req: AppointmentWriteBackApiType['input']) => appointmentWriteback(req),
    onError(error) {
      console.error('an error occurred while adding appointment writeback', error);
    },
  });
};

export const useAppointmentStatusWriteback = () => {
  return useMutation({
    mutationFn: (req: AppointmentStatusWriteBackApiType['input']) => appointmentStatusWriteback(req),
    onError(error) {
      console.error('an error occurred while adding appointment status writeback', error);
    },
  });
};

export const usePersonWriteback = () => {
  return useMutation({
    mutationFn: (req: PersonWriteBackApiType['input']) => personWriteback(req),
    onError(error) {
      console.error('an error occurred during person writeback', error);
    },
  });
};

type UseListSourceTenantsArgs = {
  locationId: string;
  isEnabled?: boolean;
};

export const useListSourceTenants = ({ locationId, isEnabled = false }: UseListSourceTenantsArgs) => {
  return useQuery({
    queryKey: [locationId, 'source-tenants'],
    queryFn: () => listSourceTenants({ locationId }),
    enabled: !!locationId && isEnabled,
  });
};

export const useGetFeatureFlagStatus = (req: GetFeatureFlagStatusApiType['input'], options?: Options) => {
  return useQuery({
    queryKey: scheduleBookingSiteQueryKeys.featureFlagStatus(req),
    queryFn: () => getFeatureFlagStatus(req, options),
    enabled: !!req.locationId && !!req.featureFlagNames.length,
    retry: 1,
  });
};

export const useListCalendarAvailabilities = (
  req: ListCalendarAvailabilitiesApiType['input'],
  queryOptions?: UseQueryOptions<ListCalendarAvailabilitiesApiType['output']>,
  httpOptions?: Options
) => {
  return useQuery({
    queryKey: scheduleBookingSiteQueryKeys.calendarAvailabilities(req),
    queryFn: () => getListCalendarAvailabilities(req, httpOptions),
    retry: 1,
    cacheTime: CACHE_AND_STALE_TIME_FOR_APPOINTMENT_OPENINGS,
    staleTime: CACHE_AND_STALE_TIME_FOR_APPOINTMENT_OPENINGS,
    ...queryOptions,
    enabled: !!req.calendarId && !!req.startDateTime && !!req.endDateTime && (queryOptions?.enabled ?? true),
  });
};
