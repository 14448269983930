import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

type Props = {
  subtitle?: string | React.ReactNode;
  title: string;
  value?: number | string;
};

export const Insights = ({ subtitle, title, value }: Props) => {
  return (
    <div css={styles.wrapper}>
      <div>
        <Text style={{ fontSize: theme.font.size.h3 }} weight='bold'>
          {title}
        </Text>
        {subtitle &&
          (typeof subtitle === 'string' ? (
            <Text color='light' size='small'>
              {subtitle}
            </Text>
          ) : (
            subtitle
          ))}
      </div>
      <Text style={{ fontSize: theme.font.size.h1 }} weight='bold'>
        {value}
      </Text>
    </div>
  );
};

const styles = {
  wrapper: css`
    align-items: center;
    background-color: ${theme.colors.primary5};
    border-radius: 0 0 ${theme.borderRadius.medium} ${theme.borderRadius.medium};
    border-top: 1px solid ${theme.colors.neutral20};
    display: flex;
    gap: ${theme.spacing(2)};
    justify-content: space-between;
    padding: ${theme.spacing(2)};

    @container (min-width: ${breakpoints.xsmall.max}px) {
      height: 90px;
    }
  `,
};
