import { memo, useEffect, useMemo } from 'react';
import { useNavigate } from '@tanstack/react-location';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { Chart, XAxisLabelValueTick } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { AnalyticsTrackingIds } from '../..';
import { DASHBOARD_CHART_SIZE, URLs } from '../../constants';
import { featureFlags } from '../../feature-flags';
import { useAnalyticsOrgLocations, useHasAclAccess } from '../../hooks';
import { appendTime, formatters } from '../../utils';
import { DemoChip } from '../demo-chip';
import { messagingCategoriesLabels } from '../sms/charts';
import { useFetchAggregatedSMSReport, useIsMessagingAnalyticsDemoAccount } from '../sms/hooks';
import { Insights } from './insights';
import { dashboardChartStyle } from './styles';

type Props = {
  filters?: AnalyticsCommonTypes.AnalyticsSummaryFilters;
  onLoadData?: (totalMessageCount: number) => void;
  onLoadingStateChange?: (isLoading?: boolean) => void;
};

type SummedCategories = {
  [category: string]: number;
};

const categoryColors = [
  theme.colors.secondary.seaweed40,
  theme.colors.tangerine40,
  theme.colors.rose40,
  theme.colors.secondary.eggplant40,
  theme.colors.critical40,
];

export const MessagingCategoriesOnDashboard = memo(({ filters, onLoadData, onLoadingStateChange }: Props) => {
  const { t } = useTranslation('analytics');
  const navigate = useNavigate();
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const messagingAnalyticsEnabled = getFeatureFlagValue(featureFlags.messagingAnalytics);
  const isDemoAccount = useIsMessagingAnalyticsDemoAccount();
  const hasAclAccess = useHasAclAccess([Permission.ANALYTICS_READ]);
  const { isLoadingLocations, locationIds } = useAnalyticsOrgLocations({
    isDemoAccount,
    module: 'SMS',
  });

  const { data = {}, isLoading } = useFetchAggregatedSMSReport('MessageCategory', {
    end_date: appendTime(filters?.endDate),
    location_id: locationIds || [],
    start_date: appendTime(filters?.startDate),
    time_zone: filters?.timeZone,
  });

  const messageCategories = useMemo(() => {
    const summedCategories: SummedCategories = {};

    Object.values(data).forEach((locationData) => {
      Object.values(locationData).forEach((dateData) => {
        Object.entries(dateData).forEach(([category, { daily }]) => {
          if (!summedCategories[category]) {
            summedCategories[category] = 0;
          }
          summedCategories[category] += daily;
        });
      });
    });

    return summedCategories;
  }, [data]);

  const topCategories = useMemo(() => {
    // Retrieve the top 5 categories
    const categories = Object.entries(messageCategories)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 5);

    const { counts, total } = categories.reduce(
      (acc, [key, value]) => {
        return {
          counts: { ...acc.counts, [key]: value },
          total: acc.total + value,
        };
      },
      {
        counts: {},
        total: 0,
      }
    );

    const colors: Record<string, string> = {};
    const chartData = {
      groups: categories.map(([name, value], index) => {
        colors[name] = categoryColors[index];

        return {
          name,
          values: {
            [name]: value,
          },
        };
      }),
    };

    return {
      colors,
      counts,
      chartData,
      total,
    };
  }, [messageCategories]);

  useEffect(() => {
    onLoadingStateChange?.(isLoading);
  }, [isLoading]);

  useEffect(() => {
    onLoadData?.(topCategories.total);
  }, [topCategories.total]);

  if (!messagingAnalyticsEnabled) {
    return null;
  }

  return (
    <Chart
      bottomContentRenderer={
        <Insights title={t('Total Outbound Messages')} value={formatters.value.shortenNumber(topCategories.total)} />
      }
      colors={topCategories.colors}
      css={dashboardChartStyle}
      isLoading={isLoading || isLoadingLocations}
      labels={{
        ...messagingCategoriesLabels,
        messages: t('Messages'),
      }}
    >
      <Chart.Header
        actions={[
          {
            label: t('Show More'),
            omit: !hasAclAccess,
            onClick: () => {
              navigate({
                to: URLs.MESSAGING_ANALYTICS,
              });
            },
            trackingId: AnalyticsTrackingIds.analytics.showMoreMessagingAnalytics,
          },
        ]}
        leftElement={isDemoAccount && <DemoChip />}
        title={t('Top Message Categories')}
      />
      <Chart.BarChart
        appearance={{
          customXAxisTick: ({ labels, groupName, ...rest }) => {
            return (
              <XAxisLabelValueTick
                {...rest}
                color={topCategories.colors[groupName]}
                label={labels?.[groupName] || groupName}
                labelColor={theme.colors.neutral60}
                value={formatters.value.format(topCategories.counts[groupName as keyof typeof topCategories.counts])}
              />
            );
          },
          groupsGap: 100,
          height: DASHBOARD_CHART_SIZE,
          margin: { bottom: 20 },
          showXAxis: true,
        }}
        data={topCategories.chartData}
        formatValue={formatters.value.format}
      />
    </Chart>
  );
});

MessagingCategoriesOnDashboard.displayName = 'MessagingCategoriesOnDashboard';
