import { useDevToolsStore } from '@frontend/devtools';
import { useScopedAppFlagStore } from '@frontend/scope';
import { useSchedulerV3QueryUpdaters } from './query-updaters';

export const useSchedularV3InvalidateQueries = () => {
  const { invalidateQueries } = useSchedulerV3QueryUpdaters();

  const invalidateGetAppointmentType = (appointmentTypeId: string) =>
    invalidateQueries({
      endpointName: 'GetAppointmentType',
      queryFilters: {
        exact: false,
        predicate: ({ queryKey }) => {
          const request = queryKey[2];
          return request.appointmentTypeId === appointmentTypeId;
        },
      },
    });

  const invalidateListAppointmentTypes = () =>
    invalidateQueries({
      endpointName: 'ListAppointmentTypes',
      queryFilters: {
        exact: false,
      },
    });

  // TODO: This functions need to be updated to optionally accept a locationId to invalidate only the queries related to that location
  const invalidateListProviders = () =>
    invalidateQueries({
      endpointName: 'ListProviders', // this will invalidate both `ListProviders` and `ListProvidersMulti` queries
      queryFilters: {
        exact: false,
      },
    });

  const invalidGetProvider = (providerId: string) =>
    invalidateQueries({
      endpointName: 'GetProvider',
      queryFilters: {
        exact: false,
        predicate: ({ queryKey }) => queryKey[2].id === providerId,
      },
    });

  const invalidateListMappings = () =>
    invalidateQueries({
      endpointName: 'ListMappings',
      queryFilters: {
        exact: false,
      },
    });

  // entityIds is an array of locationIds or providerIds
  const invalidateListSchedules = (locationId: string, entityIds: string[]) =>
    invalidateQueries({
      endpointName: 'ListSchedules',
      queryFilters: {
        exact: false,
        predicate: ({ queryKey }) => {
          const request = queryKey[2];
          return request.locationId === locationId && !!request.ids?.some((entityId) => entityIds?.includes(entityId));
        },
      },
    });

  const invalidateListBookingSubmissions = (locationId?: string) =>
    invalidateQueries({
      endpointName: 'ListBookingSubmissions',
      queryFilters: {
        exact: false,
        predicate: ({ queryKey }) => {
          const request = queryKey[2];
          return !locationId || (request.locationsIds ?? []).includes(locationId);
        },
      },
    });

  const invalidateGetBookingSubmission = (bookingSubmissionId?: string) =>
    invalidateQueries({
      endpointName: 'GetBookingSubmission',
      queryFilters: {
        exact: false,
        predicate: ({ queryKey }) => !!bookingSubmissionId || queryKey[2].submissionId === bookingSubmissionId,
      },
    });

  const invalidateListCalendarEvents = (locationId?: string) => {
    invalidateQueries({
      endpointName: 'ListCalendarEvents',
      queryFilters: {
        exact: false,
        predicate: ({ queryKey }) => {
          const request = queryKey[2];
          return !locationId || request.locationId === locationId;
        },
      },
    });

    invalidateQueries({
      endpointName: 'ListCalendarEventsMulti',
      queryFilters: {
        exact: false,
        predicate: ({ queryKey }) => {
          const request = queryKey[2];
          return !locationId || (request.locationsIds ?? []).includes(locationId);
        },
      },
    });
  };

  const invalidateGetCalendarEvent = (eventId?: string) =>
    invalidateQueries({
      endpointName: 'GetCalendarEvent',
      queryFilters: {
        exact: false,
        predicate: ({ queryKey }) => {
          const request = queryKey[2];
          return !eventId || request.eventId === eventId;
        },
      },
    });

  const invalidateListOperatories = (locationId?: string) => {
    invalidateQueries({
      endpointName: 'ListOperatories',
      queryFilters: {
        exact: false,
        predicate: ({ queryKey }) => {
          const request = queryKey[2];
          return !locationId || request.locationId === locationId;
        },
      },
    });

    invalidateQueries({
      endpointName: 'ListOperatoriesMulti',
      queryFilters: {
        exact: false,
        predicate: ({ queryKey }) => {
          const request = queryKey[2];
          return !locationId || (request.locationsIds ?? []).includes(locationId);
        },
      },
    });
  };

  const invalidateGetOperatory = (operatoryId?: string) =>
    invalidateQueries({
      endpointName: 'GetOperatory',
      queryFilters: {
        exact: false,
        predicate: ({ queryKey }) => {
          const request = queryKey[2];
          return !operatoryId || request.operatoryId === operatoryId;
        },
      },
    });

  return {
    invalidateListAppointmentTypes,
    invalidateGetAppointmentType,
    invalidateListProviders,
    invalidGetProvider,
    invalidateListMappings,
    invalidateListSchedules,
    invalidateListBookingSubmissions,
    invalidateGetBookingSubmission,
    invalidateListCalendarEvents,
    invalidateGetCalendarEvent,
    invalidateListOperatories,
    invalidateGetOperatory,
  };
};

export const useGetSchedulerV3FeatureFlagDetails = () => {
  const isDebugModeOn = useDevToolsStore((state) => state.options.isDebugModeOn);

  const { getFeatureFlagValue } = useScopedAppFlagStore();

  const hasSchedulerV3UI = getFeatureFlagValue('scheduler-v3-ui', 'ALL');

  // TODO: Remove the debug mode check when scheduler-v3 is ready to release
  // TODO: Do not remove this logic until the scheduler v3 is ready to release to the customers
  return { isScheduleV3FlagEnabled: hasSchedulerV3UI && isDebugModeOn };
};
