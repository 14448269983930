import { i18next } from '@frontend/i18n';
import { theme } from '@frontend/theme';

// These keys are based on api response
export const weaveStatusColors: Record<string, string> = {
  'In Office': theme.colors.primary50,
  'No Show': theme.colors.critical50,
  Attempted: theme.colors.secondary.eggplant60,
  Canceled: theme.colors.critical30,
  Completed: theme.colors.secondary.seaweed40,
  Confirmed: theme.colors.success30,
  Unconfirmed: theme.colors.primary20,
  Unknown: theme.colors.warning20,
};

// These keys are based on api response
export const weaveStatusLabels: Record<string, string> = {
  'In Office': i18next.t('In Office', { ns: 'analytics' }),
  'No Show': i18next.t('No Show', { ns: 'analytics' }),
  Attempted: i18next.t('Attempted', { ns: 'analytics' }),
  Canceled: i18next.t('Canceled', { ns: 'analytics' }),
  Completed: i18next.t('Completed', { ns: 'analytics' }),
  Confirmed: i18next.t('Confirmed', { ns: 'analytics' }),
  unconfirmed: i18next.t('Unconfirmed', { ns: 'analytics' }),
  Unconfirmed: i18next.t('Unconfirmed', { ns: 'analytics' }),
  Unknown: i18next.t('Unknown', { ns: 'analytics' }),
};
