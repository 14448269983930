import { memo, useEffect, useMemo } from 'react';
import { useNavigate } from '@tanstack/react-location';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { AnalyticsCommonTypes, AutoRecallAnalyticsApi } from '@frontend/api-analytics';
import { Chart, XAxisLabelValueTick } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { useAlert } from '@frontend/design-system';
import { AnalyticsTrackingIds } from '../..';
import { DASHBOARD_CHART_SIZE, URLs } from '../../constants';
import { useHasAclAccess } from '../../hooks';
import { queryKeys } from '../../query-keys';
import { appendCurrentTimeZone, formatters } from '../../utils';
import { useAutoRecallAnalyticsDemoData, useIsAutoRecallAnalyticsDemoAccount } from '../auto-recall/hooks';
import { DemoChip } from '../demo-chip';
import { dashboardChartStyle } from './styles';
import { Insights } from '.';

type Props = {
  filters: AnalyticsCommonTypes.AnalyticsSummaryFilters;
  onLoading: (isLoading: boolean) => void;
};

const colors: Record<string, string> = {
  notReceived: theme.colors.warning50,
  notScheduled: theme.colors.critical30,
  scheduled: theme.colors.secondary.seaweed40,
};

export const AutoRecallOnDashboard = memo(({ filters, onLoading }: Props) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const navigate = useNavigate();
  const hasAclAccess = useHasAclAccess([Permission.ANALYTICS_READ]);
  const isDemoAccount = useIsAutoRecallAnalyticsDemoAccount();
  const demoData = useAutoRecallAnalyticsDemoData();

  const payload = useMemo(
    () => ({
      endDate: appendCurrentTimeZone(filters.endDate.format('YYYY-MM-DDT23:59:59')),
      startDate: appendCurrentTimeZone(filters.startDate.format('YYYY-MM-DDT00:00:00')),
    }),
    [filters]
  );

  const { data, isFetching: isFetchingSummaryData } = useScopedQuery({
    queryKey: queryKeys.analyticsDashboard(`auto-recall-${JSON.stringify(payload)}-${isDemoAccount}`),
    queryFn: () => (isDemoAccount ? null : AutoRecallAnalyticsApi.getAutoRecallData(payload || {})),
    onError: () => {
      alert.error(t("Couldn't load the auto recall data. Please try again."));
    },
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      return isDemoAccount ? demoData?.chartsData : data;
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const formattedData = useMemo(() => {
    if (!data) {
      return {
        chartData: {
          groups: [],
        },
        counts: {
          notReceived: 0,
          notScheduled: 0,
          scheduled: 0,
          total: 0,
        },
      };
    }

    const counts = {
      scheduled: data?.Success?.length || 0,
      notReceived: data?.FailedAttempt?.length || 0,
      notScheduled: data?.Attempted?.length || 0,
    };

    return {
      chartData: {
        groups: Object.entries(counts).map(([name, value]) => ({
          name,
          values: {
            [name]: value,
          },
        })),
      },
      counts: {
        ...counts,
        total: counts.scheduled + counts.notReceived + counts.notScheduled,
      },
    };
  }, [data]);

  useEffect(() => {
    onLoading(isFetchingSummaryData);
  }, [isFetchingSummaryData]);

  return (
    <Chart
      bottomContentRenderer={<Insights title={t('Patients Contacted')} value={formattedData.counts.total} />}
      colors={colors}
      css={dashboardChartStyle}
      isLoading={isFetchingSummaryData}
      labels={{
        notReceived: t('Not Received'),
        notScheduled: t('Not Scheduled'),
        patients: t('Patients'),
        scheduled: t('Scheduled'),
      }}
    >
      <Chart.Header
        actions={[
          {
            label: t('Show More'),
            omit: !hasAclAccess,
            onClick: () => {
              navigate({ to: URLs.AUTO_RECALL_ANALYTICS });
            },
            trackingId: AnalyticsTrackingIds.analytics.showMoreAutoRecallAnalytics,
          },
        ]}
        leftElement={isDemoAccount && <DemoChip />}
        title={t('Auto Recall')}
      />
      <Chart.BarChart
        appearance={{
          customXAxisTick: ({ labels, groupName, ...rest }) => {
            return (
              <XAxisLabelValueTick
                {...rest}
                color={colors[groupName]}
                label={labels?.[groupName] || groupName}
                labelColor={theme.colors.neutral60}
                value={formatters.value.format(formattedData.counts[groupName as keyof typeof formattedData.counts])}
              />
            );
          },
          height: DASHBOARD_CHART_SIZE,
          margin: { bottom: 20 },
          showXAxis: true,
        }}
        data={formattedData.chartData}
        formatValue={formatters.value.format}
      />
    </Chart>
  );
});

AutoRecallOnDashboard.displayName = 'AutoRecallOnDashboard';
