import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

const fieldItemStyle = css`
  width: 100%;
  box-shadow: ${theme.shadows.light};
  border-radius: ${theme.borderRadius.small};

  .inner-container {
    display: flex;
    align-items: center;
    padding: ${theme.spacing(1, 2)};
    gap: ${theme.spacing(1)};

    .drag-icon {
      flex-shrink: 0;
    }
  }
`;

export type FieldItemAppearance = 'default' | 'subdued' | 'disabled';

interface FieldItemConfig {
  isDragging?: boolean;
  appearance?: FieldItemAppearance;
}

export function getFieldItemStyle({ appearance, isDragging }: FieldItemConfig) {
  if (isDragging) {
    return [
      fieldItemStyle,
      css`
        box-shadow: none;

        .inner-container {
          background-color: ${theme.colors.neutral5};
          transform: rotate(5deg);
          box-shadow: ${theme.shadows.light};
          border-radius: ${theme.borderRadius.small};
        }
      `,
    ];
  }

  switch (appearance) {
    case 'subdued':
      return [
        fieldItemStyle,
        css`
          background-color: ${theme.colors.neutral10};
        `,
      ];

    case 'disabled':
      return [
        fieldItemStyle,
        css`
          box-shadow: none;
          background-color: ${theme.colors.neutral5};
          color: ${theme.colors.text.disabled};
          cursor: not-allowed;
        `,
      ];

    default:
      return fieldItemStyle;
  }
}
