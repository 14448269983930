import { useState } from 'react';
import { css } from '@emotion/react';
import { Operatory } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/operatory.pb';
import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { useTranslation } from '@frontend/i18n';
import { IconName } from '@frontend/icons';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { FormRow, TextField, useForm, Text, useAlert, ButtonBar, Button } from '@frontend/design-system';
import { useSchedulingLocationInfo } from '../../hooks';
import { OperatoryIconConfig, OperatoryIconSelector } from './OperatoryIconSelector';

type ManageOperatoryEditProps = {
  operatoryDetails?: Operatory;
  locationId: string;
};

export const ManageOperatoryEdit = ({ operatoryDetails, locationId }: ManageOperatoryEditProps) => {
  const { t } = useTranslation('scheduleGlobalSettings');
  const alert = useAlert();

  const { navigate } = useSettingsNavigate();

  const { isMultiLocation } = useSchedulingLocationInfo();

  const updateOperatoryMutation = SchedulerV3.Mutations.useUpdateOperatoryMutation();

  const [iconConfig, setIconConfig] = useState<OperatoryIconConfig>({
    color: operatoryDetails?.details?.icon?.color || theme.colors.neutral20,
    iconName: (operatoryDetails?.details?.icon?.name || 'wellness-small') as IconName,
  });

  const handleIconSelection = (config: OperatoryIconConfig) => {
    setIconConfig(config);
  };

  const { formProps, getFieldProps } = useForm({
    fields: {
      treatmentRoomName: {
        type: 'text',
        required: true,
        value: operatoryDetails?.externalName,
      },
      displayName: {
        type: 'text',
        value: operatoryDetails?.displayName ?? '',
      },
    },
    onSubmit: (values) => {
      handleUpdateOperatory(values.displayName || '');
    },
  });

  const navigateToTable = () => {
    if (isMultiLocation) {
      navigate({
        to: '/schedule/treatment-rooms/:locationId',
        params: { locationId },
      });
    } else {
      navigate({
        to: '/schedule/treatment-rooms',
      });
    }
  };

  const handleUpdateOperatory = (displayName: string) => {
    updateOperatoryMutation
      .mutateAsync({
        operatoryId: operatoryDetails?.id ?? '',
        displayName,
        details: {
          ...operatoryDetails?.details,
          icon: {
            color: iconConfig.color,
            name: iconConfig.iconName,
          },
        },
      })
      .then(() => {
        alert.success(t('Treatment room details updated successfully'));
        navigateToTable();
      })
      .catch(() => {
        alert.error(t('Failed to update Treatment room details'));
      });
  };

  return (
    <section css={editOperatoryContainer}>
      <form {...formProps}>
        <FormRow css={{ marginBottom: theme.spacing(0) }}>
          <TextField disabled={true} {...getFieldProps('treatmentRoomName')} label={t('Treatment Room Name')} />
        </FormRow>
        <Text css={{ marginTop: theme.spacing(1) }} size='small' color='light'>
          {t('The treatment room name as it appears in your Practice Management System')}
        </Text>
        <FormRow cols={[15, 85]} css={{ margin: theme.spacing(2, 0, 0, 0) }}>
          <OperatoryIconSelector
            operatoryIconConfig={{
              iconName: (iconConfig.iconName || 'wellness-small') as IconName,
              color: iconConfig.color || theme.colors.rose[20],
            }}
            onOperatoryIconChange={handleIconSelection}
          />
          <div css={{ marginTop: theme.spacing(0.5) }}>
            <TextField {...getFieldProps('displayName')} label={t('Display Name')} />
            <Text css={{ marginTop: theme.spacing(1) }} size='small' color='light'>
              {t('This name will be visible on your Weave calendar.')}
            </Text>
          </div>
        </FormRow>

        <ButtonBar>
          <Button variant='secondary' onClick={navigateToTable}>
            {t('Cancel')}
          </Button>
          <Button type='submit' loading={updateOperatoryMutation.isLoading}>
            {t('Save')}
          </Button>
        </ButtonBar>
      </form>
    </section>
  );
};

const editOperatoryContainer = css({
  width: '80%',
  padding: theme.spacing(4),
  backgroundColor: theme.colors.white,
  borderRadius: theme.borderRadius.medium,
});
