export const featureFlags = {
  // Appointment
  appointmentAnalyticsDemoData: 'appointment-analytics-demo-data',

  // Auto Recall
  autoRecallDemoData: 'auto-recall-analytics-demo-data',
  disableAutoRecallInAnalytics: 'disable-auto-recall-in-analytics',

  // Call Intel
  enableCallIntelServiceQuality: 'enable-call-intel-service-quality',
  enableCallIntelEditAi: 'enable-call-intel-edit-ai-output',
  editAiDesignVariant: 'edit-ai-output-design-variant',

  // Messaging
  messagingAnalytics: 'sms-reporting-enabled',
  messagingAnalyticsDemoData: 'messaging-analytics-demo-data',

  // Phone
  phoneAnalytics: 'phone-reporting-enabled',
  phoneAnalyticsDemoData: 'phone-analytics-demo-data',

  // Practice
  practiceAnalyticsDemo: 'analytics-demo-data',

  // ROI
  appointmentRemindersRoi: 'appointment-reminders-roi',
  appointmentRemindersRoiDemoData: 'appointment-reminders-roi-demo-data',
  bulkTextRoi: 'bulk-text-roi',
  bulkTextRoiDemoData: 'bulk-text-roi-demo-data',
  missedCallTextRoiDemoData: 'missed-call-text-roi-demo-data',
};
