import { memo, useEffect } from 'react';
import { useNavigate } from '@tanstack/react-location';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { AnalyticsTrackingIds } from '../..';
import { DASHBOARD_CHART_SIZE, URLs } from '../../constants';
import { featureFlags } from '../../feature-flags';
import { useAnalyticsOrgLocations, useHasAclAccess } from '../../hooks';
import { appendTime, formatters } from '../../utils';
import { DemoChip } from '../demo-chip';
import { useFetchAggregatedCallsSummary, useIsPhoneAnalyticsDemoAccount } from '../phone/hooks';
import { Insights } from './insights';
import { dashboardChartStyle } from './styles';
import { PhoneSummary } from './types';

type Props = {
  filters?: AnalyticsCommonTypes.AnalyticsSummaryFilters;
  onLoadData?: (summary: PhoneSummary) => void;
  onLoadingStateChange?: (isLoading?: boolean) => void;
};

const colors = {
  abandoned: theme.colors.warning50,
  answered: theme.colors.success30,
  forwarded: theme.colors.primary20,
  missed: theme.colors.critical30,
};

const labels = {
  abandoned: i18next.t('Abandoned', { ns: 'analytics' }),
  answered: i18next.t('Answered', { ns: 'analytics' }),
  forwarded: i18next.t('Forwarded', { ns: 'analytics' }),
  missed: i18next.t('Missed', { ns: 'analytics' }),
  total: i18next.t('Total', { ns: 'analytics' }),
};

export const PhoneIncomingCategoriesOnDashboard = memo(({ filters, onLoadData, onLoadingStateChange }: Props) => {
  const { t } = useTranslation('analytics');
  const navigate = useNavigate();
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const phoneAnalyticsEnabled = getFeatureFlagValue(featureFlags.phoneAnalytics);
  const isDemoAccount = useIsPhoneAnalyticsDemoAccount();
  const hasAclAccess = useHasAclAccess([Permission.ANALYTICS_READ]);
  const { isLoadingLocations, locationIds } = useAnalyticsOrgLocations({
    isDemoAccount,
    module: 'PHONE',
  });

  const { data, isLoading } = useFetchAggregatedCallsSummary({
    end_date: appendTime(filters?.endDate),
    location_id: locationIds || [],
    start_date: appendTime(filters?.startDate),
    time_zone: filters?.timeZone,
  });

  const incomingCalls = {
    abandoned: data?.answerRate?.abandoned || 0,
    answered: data?.answerRate?.answered || 0,
    missed: data?.answerRate?.missed || 0,
    total: data?.totalCalls?.inbound || 0,
  };

  const forwardedCalls =
    incomingCalls.total - (incomingCalls.abandoned + incomingCalls.answered + incomingCalls.missed);

  useEffect(() => {
    onLoadingStateChange?.(isLoading);
  }, [isLoading]);

  useEffect(() => {
    onLoadData?.({
      answered: data?.answerRate?.answered || 0,
      incoming: data?.totalCalls?.inbound || 0,
      total: data?.totalCalls?.total || 0,
    });
  }, [data]);

  if (!phoneAnalyticsEnabled) {
    return null;
  }

  return (
    <Chart
      bottomContentRenderer={
        <Insights title={t('Call Answer Rate')} value={`${(data?.answerRate?.rate || 0).toFixed(2)}%`} />
      }
      colors={colors}
      css={dashboardChartStyle}
      commonTooltipLabel={t('Calls')}
      isLoading={isLoading || isLoadingLocations}
      labels={labels}
    >
      <Chart.Header
        actions={[
          {
            label: t('Show More'),
            omit: !hasAclAccess,
            onClick: () => {
              navigate({
                to: URLs.PHONE_ANALYTICS,
              });
            },
            trackingId: AnalyticsTrackingIds.analytics.showMorePhoneAnalytics,
          },
        ]}
        leftElement={isDemoAccount && <DemoChip />}
        title={t('Incoming Phone Calls')}
      />
      <Chart.PieChart
        appearance={{
          width: DASHBOARD_CHART_SIZE,
          height: DASHBOARD_CHART_SIZE,
          innerRadius: 55,
          outerRadius: 95,
        }}
        data={{
          centerMetric: formatters.value.format(incomingCalls.total),
          centerMetricLabel: labels.total,
          groups: [
            {
              name: 'answered',
              value: incomingCalls.answered,
            },
            {
              name: 'abandoned',
              value: incomingCalls.abandoned,
            },
            {
              name: 'missed',
              value: incomingCalls.missed,
            },
            {
              name: 'forwarded',
              value: forwardedCalls,
            },
          ],
        }}
      />
      {!!incomingCalls.total && (
        <Chart.Legends
          values={{
            answered: formatters.value.format(incomingCalls.answered),
            forwarded: formatters.value.format(forwardedCalls),
            missed: formatters.value.format(incomingCalls.missed),
            abandoned: formatters.value.format(incomingCalls.abandoned),
          }}
        />
      )}
    </Chart>
  );
});

PhoneIncomingCategoriesOnDashboard.displayName = 'PhoneIncomingCategoriesOnDashboard';
