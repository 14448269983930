import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { SchedulerV3Types } from '@frontend/api-scheduler-v3';
import { useSchedulingLocationInfo } from '../use-scheduling-location-info';

type UseGetOperatoryV3DataParamsType = {
  selectedLocationId: string;
  selectedLocationIds: string[];
  debouncedSearchValue?: string;
  pageConfig?: SchedulerV3Types.PageConfig;
  isEnabled?: boolean;
};

export const useGetOperatoryV3Data = ({
  selectedLocationId,
  debouncedSearchValue,
  pageConfig,
  selectedLocationIds,
  isEnabled = true,
}: UseGetOperatoryV3DataParamsType) => {
  const { isMultiLocation, parentLocationId } = useSchedulingLocationInfo();
  const isMultiLocationApiCall = !!parentLocationId && isMultiLocation;

  const listOperatoriesQuery = SchedulerV3.Queries.useListOperatoriesQuery({
    request: {
      locationId: selectedLocationId,
      ...(pageConfig?.limit && { limit: pageConfig?.limit }),
      ...(pageConfig?.page && { page: pageConfig?.page }),
      ...(debouncedSearchValue && { name: debouncedSearchValue }),
    },
    options: {
      enabled: !isMultiLocationApiCall && isEnabled,
    },
  });

  const listOperatoriesMultiQuery = SchedulerV3.Queries.useListOperatoriesMultiQuery({
    request: {
      locationsIds: selectedLocationIds || [selectedLocationId],
      parentLocationId: parentLocationId || '',
      ...(pageConfig?.limit && { limit: pageConfig?.limit }),
      ...(pageConfig?.page && { page: pageConfig?.page }),
      ...(debouncedSearchValue && { name: debouncedSearchValue }),
    },
    options: {
      enabled: isMultiLocationApiCall && isEnabled,
    },
  });

  return isMultiLocationApiCall ? listOperatoriesMultiQuery : listOperatoriesQuery;
};
