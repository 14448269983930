export const queryKeys = {
  base: ['analytics'] as const,
  analyticsDashboard: (query: string) => [...queryKeys.base, 'analyticsDashboard', query],
  appointmentAnalyticsCharts: (query?: string) => [...queryKeys.base, 'appointmentAnalyticsCharts', query],
  appointmentAnalyticsReport: (query?: string) => [...queryKeys.base, 'appointmentAnalyticsReport', query],
  autoRecallAnalytics: (query?: string) => [...queryKeys.base, 'autoRecallAnalytics', query],
  callIntelligence: (query: string) => [...queryKeys.base, 'callIntelligence', query],
  callResults: (query?: string) => [...queryKeys.base, 'callResults', query],
  listCallRecording: (tenantId: string) => ['phone', 'listCallRecording', tenantId],
  roiPatients: (query?: string) => [...queryKeys.base, 'roiPatients', query],
  morningHuddle: (query?: string) => [...queryKeys.base, 'morningHuddle', query],
  morningHuddleReportEmails: (query?: string) => [...queryKeys.base, 'morningHuddleReportEmails', query],
  phoneAnalyticsCharts: (query?: string) => [...queryKeys.base, 'phoneAnalyticsCharts', query],
  phoneAnalyticsReport: (query?: string) => [...queryKeys.base, 'phoneAnalyticsReport', query],
  phoneFilterOptions: () => [...queryKeys.base, 'phoneFilterOptions'],
  phoneSnapshotReport: (query?: string) => [...queryKeys.base, 'phoneSnapshotReport', query],
  practiceAnalyticsCharts: (query?: string) => [...queryKeys.base, 'practiceAnalyticsCharts', query],
  practiceAnalyticsTables: (query?: string) => [...queryKeys.base, 'practiceAnalyticsTables', query],
  roiCharts: (query?: string) => [...queryKeys.base, 'roiCharts', query],
  smsAnalyticsCharts: (query?: string) => [...queryKeys.base, 'smsAnalyticsCharts', query],
  smsAnalyticsReport: (query?: string) => [...queryKeys.base, 'smsAnalyticsReport', query],
  smsFilterOptions: () => [...queryKeys.base, 'smsFilterOptions'],
};
